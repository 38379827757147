@import "./media.less";

:root {
  // Sizes
  --page-max-width: 1280px;
  --sidenav-height: 74px;
  --page-grid: [full-start] var(--spacing-gutter) [main-start sidebar-start]
    minmax(0, 1fr) [sidebar-end content-start] minmax(0, 3.5fr) [content-end
    main-end] var(--spacing-gutter) [full-end];

  // Spaces
  --spacing-big: 70px;
  --spacing-medium: 50px;
  --spacing-regular: 40px;
  --spacing-sm-1: 32px;
  --spacing-sm-2: 24px;
  --spacing-sm-3: 16px;
  --spacing-sm-4: 12px;
  --spacing-sm-5: 6px;
  --spacing-gutter: 50px;
  --spacing-banner-horizontal: 108px;
  --spacing-section-vertical: 60px;
  --spacing-content: 32px;
  --spacing-content-small: 26px;

  // Typography
  --f-title-huge: 48px;
  --f-title-big: 36px;
  --f-title: 24px;
  --f-title-small: 20px;
  --f-basic: 16px;
  --f-small: 14px;
  --f-small-2: 12px;
  --lh-title: 57px;
  --lh-section-title: 46px;
  --lh-title-mobile: 42px;
  --lh-paragraph: 24px;
  --lh-list-item: 16px;

  // Colors
  --c-white: #fff;
  --c-black: #000;
  --c-light-grey-1: #f7f7f7;
  --c-light-grey-2: #ccc;
  --c-light-grey-3: #e5e5e5;
  --c-grey: #707070;
  --c-light-blue: #93c9ff;
  --c-violet: #a60d8a;
  --c-purple: #382aef;
  --c-blue: #3665f3;
  --c-subtext: #191919;
  --c-red: #e0103a;
  --c-skeleton: #f9f9f9;
  --c-title: #191919;
  --g-banner: linear-gradient(to right, rgba(0 0 0 / 60%), transparent);
  --g-banner-top: linear-gradient(
    to top,
    rgba(0 0 0 / 60%) 0%,
    transparent 50%
  );
  --g-banner-mobile: linear-gradient(to right, rgba(0 0 0 / 60%), transparent);

  // Effects
  --radius: 16px;
  --radius-small: 8px;
  --filter-text-background: brightness(0.7);
  --filter-dimming: brightness(0.97);

  // zIndices
  --z-max: 9999; // TODO: this might create more issues than it solves

  @media (max-width: @resolution-sidenav) {
    --page-grid: [full-start] var(--spacing-gutter) [main-start sidebar-start
      content-start] minmax(0, 1fr) [sidebar-end content-end main-end]
      var(--spacing-gutter) [full-end];
  }

  @media (max-width: @resolution-tablet) {
    --spacing-gutter: 32px;
    --spacing-banner-horizontal: 64px;
    --spacing-content-small: 16px;
  }

  @media (max-width: @resolution-mobile) {
    --spacing-gutter: 16px;
    --spacing-banner-horizontal: 24px;
  }
}
